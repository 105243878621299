import { useFieldArray, useFormContext } from 'react-hook-form';
import { useDrop } from 'react-dnd';
import { useRef } from 'react';
import { useUIContext } from '../../../context/UIContext';
import Label from './Label';

const LabelsBox = () => {
  const ref = useRef(null);
  const { currentQuestion } = useUIContext();
  const { control } = useFormContext();
  const { fields: labels, remove, append } = useFieldArray({ name: `${currentQuestion._id}.legend.labels`, control });
  const [{ canDrop, isOver }, drop] = useDrop({
    accept: 'LABEL',
    collect: (monitor) => ({
      canDrop: monitor.canDrop(),
      isOver: monitor.isOver(),
    }),
    drop: (item) => {
      if (item.listIndex === 'none') {
        return;
      }

      const itemCopy = { ...item };
      delete itemCopy.id;
      delete itemCopy.index;
      delete itemCopy.listIndex;

      item.remove();
      append(itemCopy);
    },
  });

  drop(ref);

  return (
    <div className="organize-labels-container">
      <div className="organize-list-title">Éléments à placer</div>
      <div
        ref={ref}
        className="organize-base-drop"
        style={{
          backgroundColor: isOver ? '#9BA1CB' : canDrop ? '#F5F5FB' : 'white',
        }}
      >
        {labels.map((label, index) => (
          <Label
            key={label._id}
            data={{
              ...label,
              index,
              listIndex: 'none',
              remove: () => remove(index),
              replace: (label) => {
                remove(index);
                append(label);
              },
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default LabelsBox;
