import { useFieldArray, useFormContext } from 'react-hook-form';
import ColumnLabels from './ColumnLabels';
import { useUIContext } from '../../../context/UIContext';

const Column = () => {
  const { currentQuestion } = useUIContext();
  const { control } = useFormContext();
  const { fields: lists } = useFieldArray({ name: `${currentQuestion._id}.organize.lists`, control });

  return (
    <div className="organize-container">
      {lists.map((list, index) => (
        <div key={`list-${index}`} className="organize-column">
          <div className="organize-list-title">{list.title}</div>
          <ColumnLabels listIndex={index} />
        </div>
      ))}
    </div>
  );
};

export default Column;
