import { useRef } from 'react';
import { useDrag } from 'react-dnd';
import Move from '../../../assets/move.svg';

const Label = ({ data }) => {
  const ref = useRef(null);
  const [{ isDragging }, drag, preview] = useDrag({
    type: 'LABEL',
    item: data,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0.4 : 1;
  drag(preview(ref));

  return (
    <div ref={ref} className="organize-label" style={{ opacity }}>
      {data.content}
      <img src={Move} alt="move icon" className="ordering-element-move" />
    </div>
  );
};

export default Label;
