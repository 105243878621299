import differenceInMonths from 'date-fns/differenceInMonths';

export const getTest = () => {
  return new Promise(async (resolve, reject) => {
    const params = new URLSearchParams(window.location.search);
    const token = params.get('t');

    if (!token) {
      return reject('TOKEN_MISSING');
    }

    let res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/generate/${token}`);
    res = await res.json();

    if (!res || res?.status !== 200) {
      return reject('TOKEN_ERROR');
    }

    if (differenceInMonths(new Date(), new Date(res.data.test.createdAt)) > 3) {
      return reject('EXPIRED_ERROR');
    }

    return resolve(res.data);
  });
};

export const postForm = (data, token) => {
  return new Promise(async (resolve, reject) => {
    try {
      let res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/fill/${token}`, {
        method: 'put',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      res = await res.json();

      if (!res || res?.status !== 200) {
        if (!!res?.message) {
          return reject(res.message);
        } else {
          return reject(res.data);
        }
      }

      return resolve();
    } catch (error) {
      return reject(error);
    }
  });
};

export const postEvaluation = (data, token) => {
  return new Promise(async (resolve, reject) => {
    let res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/evaluation/${token}`, {
      method: 'put',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    res = await res.json();

    if (!res || res?.status !== 200) {
      return reject(res.data);
    }

    return resolve();
  });
};

export const postQuiz = (data, token) => {
  const formatedData = Object.values(data);

  return new Promise(async (resolve, reject) => {
    let res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/complete/${token}`, {
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(formatedData),
    });

    res = await res.json();

    if (!res || res?.status !== 200) {
      return reject(res.data);
    }

    return resolve();
  });
};
