import { useDrop } from 'react-dnd';
import { useRef } from 'react';
import Label from './Label';

const LegendBoxLabel = ({ data, update }) => {
  const ref = useRef(null);
  const [{ canDrop, isOver }, drop] = useDrop({
    accept: 'LABEL',
    collect: (monitor) => ({
      canDrop: monitor.canDrop(),
      isOver: monitor.isOver(),
    }),
    drop: (item) => {
      if (item.listIndex === data.index) return;
      update(data.index, { ...data, label: item });

      if (data?.label) {
        item.replace(data.label);
      } else {
        item.remove();
      }
      update(data.index, { ...data, label: item });
    },
  });

  drop(ref);

  return (
    <div
      ref={ref}
      style={{ minHeight: '60px', width: '100%', backgroundColor: isOver ? '#9BA1CB' : canDrop ? '#F5F5FB' : 'white' }}
    >
      {data?.label && (
        <Label
          data={{
            ...data.label,
            listIndex: data.index,
            remove: () => update(data.index, { ...data, label: null }),
            replace: (label) => update(data.index, { ...data, label }),
          }}
        />
      )}
    </div>
  );
};

export default LegendBoxLabel;
