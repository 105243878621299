import { useFormContext, Controller, useFieldArray } from 'react-hook-form';
import { useUIContext } from '../../context/UIContext';

const Checkbox = () => {
  const { currentQuestion } = useUIContext();
  const { control } = useFormContext();
  const { fields: checkbox } = useFieldArray({ control, name: `${currentQuestion._id}.checkbox` });

  return checkbox.map((item, index) => (
    <div key={item.id} className="checkbox-container">
      <label className="checkbox-label">
        <Controller
          key={`${currentQuestion._id}-${item.id}`}
          name={`${currentQuestion._id}.checkbox.${index}.valid`}
          render={({ field }) => <input {...field} type="checkbox" className="checkbox-input" />}
        />
        <div className="checkbox-box" />
        <p className="checkbox-text">{item.label}</p>
      </label>
    </div>
  ));
};

export default Checkbox;
