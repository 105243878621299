import LabelsBox from './LabelsBox';
import LegendBox from './LegendBox';

const Legend = () => (
  <div>
    <LegendBox />
    <LabelsBox />
  </div>
);

export default Legend;
