import { useDrag, useDrop } from 'react-dnd';
import { useRef, memo } from 'react';
import Move from '../../../assets/move.svg';

const TagDrag = memo(({ data, onMove }) => {
  const ref = useRef(null);
  const previewRef = useRef(null);
  const [, drop] = useDrop({
    accept: 'CARD',
    hover: (item) => {
      const dragIndex = item.index;
      const hoverIndex = data.index;

      if (dragIndex === hoverIndex) return;

      onMove(dragIndex, hoverIndex);

      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    type: 'CARD',
    item: data,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0.2 : 1;

  drag(ref);
  drop(preview(previewRef));

  return (
    <div ref={previewRef} style={{ opacity }}>
      <div ref={ref} className="ordering-element">
        {data.label}
        <img src={Move} alt="move icon" className="ordering-element-move" />
      </div>
    </div>
  );
});

export default TagDrag;
