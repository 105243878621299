import cn from 'classnames';
import { useFormContext, Controller } from 'react-hook-form';
import { useUIContext } from '../../context/UIContext';

const TrueFalse = () => {
  const { currentQuestion } = useUIContext();
  const { control } = useFormContext();

  return (
    <div className="truefalse-container">
      <Controller
        name={`${currentQuestion._id}.trueFalse`}
        control={control}
        render={({ field }) => (
          <div>
            <button
              type="button"
              className={cn(`true-button`, {
                selected: field.value,
              })}
              onClick={() => field.onChange(true)}
            >
              Vrai
            </button>
            <button
              type="button"
              className={cn(`false-button`, {
                selected: field.value === false,
              })}
              onClick={() => field.onChange(false)}
            >
              Faux
            </button>
          </div>
        )}
      />
    </div>
  );
};

export default TrueFalse;
