import { useFormContext, useFieldArray } from 'react-hook-form';
import { useUIContext } from '../../../context/UIContext';
import TagDrag from './TagDrag';

const Ordering = () => {
  const { currentQuestion } = useUIContext();
  const { control } = useFormContext();
  const { fields: ordering, move } = useFieldArray({ control, name: `${currentQuestion._id}.ordering` });

  return (
    <div>
      {ordering.map((label, index) => (
        <TagDrag key={label._id} data={{ ...label, index }} onMove={move} />
      ))}
    </div>
  );
};

export default Ordering;
