import { createContext, useContext, useReducer, useCallback, useMemo } from 'react';

const initialState = {
  currentStep: 'loading',
  currentQuestion: null,
  quiz: [],
  test: {},
  evaluation: {},
  error: null,
  nextStep: () => {},
  nextQuestion: () => {},
  setError: () => {},
  setData: () => {},
};

const UIContext = createContext(initialState);

const uiReducer = (state, action) => {
  switch (action.type) {
    case 'SET_DATA':
      let evaluation = {};

      if (typeof action.data.test.evaluation === 'object' && 'name' in action.data.test.evaluation) {
        evaluation = action.data.test.evaluation;
      }

      return { ...state, test: action.data.test, quiz: action.data.quiz, evaluation };
    case 'SET_ERROR':
      return { ...state, currentStep: 'error', error: action.errorCode };
    case 'SET_STEP':
      switch (state.currentStep) {
        case 'loading':
          return { ...state, currentStep: 'form' };
        case 'form':
          return { ...state, currentStep: 'instructions' };
        case 'instructions':
          if ('name' in state.evaluation) {
            return { ...state, currentStep: 'evaluation' };
          } else {
            return { ...state, currentStep: 'quiz', currentQuestion: { ...state.quiz[0], index: 0 } };
          }
        case 'evaluation':
          return { ...state, currentStep: 'quiz', currentQuestion: { ...state.quiz[0], index: 0 } };
        case 'quiz':
          return { ...state, currentStep: 'end' };
        default:
          return state;
      }
    case 'NEXT_QUESTION':
      if (state.currentQuestion.index === state.quiz.length - 1) {
        return { ...state, currentStep: 'end' };
      }

      return {
        ...state,
        currentQuestion: { ...state.quiz[state.currentQuestion.index + 1], index: state.currentQuestion.index + 1 },
      };
    default:
      return state;
  }
};

export const UIProvider = ({ children }) => {
  const [state, dispatch] = useReducer(uiReducer, initialState);

  const currentStep = useMemo(() => state.currentStep, [state.currentStep]);
  const error = useMemo(() => state.error, [state.error]);
  const test = useMemo(() => state.test, [state.test]);
  const evaluation = useMemo(() => state.evaluation, [state.evaluation]);
  const quiz = useMemo(() => state.quiz, [state.quiz]);
  const currentQuestion = useMemo(() => state.currentQuestion, [state.currentQuestion]);

  const nextStep = useCallback(() => dispatch({ type: 'SET_STEP' }), [dispatch]);
  const setError = useCallback((errorCode) => dispatch({ type: 'SET_ERROR', errorCode }), [dispatch]);
  const setData = useCallback((data) => dispatch({ type: 'SET_DATA', data }), [dispatch]);
  const nextQuestion = useCallback(() => dispatch({ type: 'NEXT_QUESTION' }), [dispatch]);

  const value = useMemo(
    () => ({ currentStep, nextStep, error, setError, setData, test, quiz, currentQuestion, nextQuestion, evaluation }),
    [currentStep, nextStep, error, setError, setData, test, quiz, currentQuestion, nextQuestion, evaluation],
  );

  return <UIContext.Provider value={value}>{children}</UIContext.Provider>;
};

export const useUIContext = () => useContext(UIContext);
