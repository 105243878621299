import { useState, useEffect } from 'react';
import Logo from './Logo';
import LogoWhite from './LogoWhite';
import Imaginer from './imaginer';
import Clock from '../assets/clock.svg';
import { useUIContext } from '../context/UIContext';
import Atlas from '../assets/atlas.png';

// prettier-ignore
const errorMessages = {
  TOKEN_MISSING: ["Aucun test de positionnement n'a été trouvé.","Nous vous invitons à vous rapprocher de votre contact Arinfo."],
  TOKEN_ERROR: ["Aucun test de positionnement n'a été trouvé.","Nous vous invitons à vous rapprocher de votre contact Arinfo."],
  DEVICE_ERROR: ['Vous ne pouvez pas passer le test de positionnement sur cet appareil, veuillez utiliser un ordinateur.'],
  WIDTH_ERROR: ["L'écran que vous utilisez est trop petit, essayez sur un autre ordinateur."],
  EXPIRED_ERROR: ["Test de positionnement expiré.","Nous vous invitons à vous rapprocher de votre contact Arinfo."],
};

const Layout = ({ children, onTimeOut }) => {
  const { currentStep, currentQuestion, error, quiz, test, nextQuestion } = useUIContext();
  const [timeLeft, setTimeLeft] = useState(currentQuestion?.duration || 0);
  const [current, setCurrent] = useState(currentQuestion?.index || null);

  useEffect(() => {
    if (currentStep === 'quiz') {
      if (currentQuestion.index !== current) {
        setCurrent(currentQuestion.index);
        setTimeLeft(currentQuestion.duration);
      }

      const timerInterval = setInterval(() => {
        if (timeLeft - 1 === 0) {
          if (currentQuestion.index === quiz.length - 1) {
            onTimeOut();
          } else {
            nextQuestion();
          }
        } else {
          setTimeLeft((timeLeft) => timeLeft - 1);
        }
      }, 1000);

      return () => clearInterval(timerInterval);
    }
  }, [currentStep, currentQuestion, current, timeLeft]);

  return (
    <div className="layout-container">
      <nav className="layout-nav-container">
        <span className="layout-nav-logo-container">
          <Logo style={{ height: '100%' }} />
        </span>
      </nav>
      <header className="layout-header-container">
        <div className="layout-header-title-container">
          <h2 className="layout-header-title">
            Test de positionnement
            <span className="layout-header-type">
              {currentStep === 'loading' || currentStep === 'error' ? '' : ' | ' + test.type.title}
            </span>
          </h2>
        </div>
      </header>
      {['loading', 'error'].includes(currentStep) && (
        <p className="layout-loading-error">
          {currentStep === 'loading' ? (
            <span>Chargement en cours...</span>
          ) : (
            currentStep === 'error' &&
            errorMessages[error].map((item) => (
              <span>
                {item}
                <br />
              </span>
            ))
          )}
        </p>
      )}
      {!['loading', 'error'].includes(currentStep) ? (
        <div className="layout-content-container">
          <div className="layout-content-header-container">
            <p className="layout-content-header-title">
              {currentStep === 'form' && <span>Bienvenue !</span>}
              {currentStep === 'instructions' && <span>Consignes</span>}
              {currentStep === 'evaluation' && <span>Auto-évaluation</span>}
              {currentStep === 'quiz' && <span>{`Question ${currentQuestion.index + 1}/${quiz.length}`}</span>}
              {currentStep === 'end' && <span>Merci d'avoir complété le test !</span>}
            </p>
            {currentStep === 'quiz' && (
              <div className="clock-container">
                <p className="clock-count">{timeLeft}</p>
                <span className="clock-count" style={{ marginLeft: 0 }}>
                  s
                </span>
                <img src={Clock} alt="" style={{ maxWidth: 30, marginLeft: 10 }} />
              </div>
            )}
          </div>
          <div className="layout-body">{children}</div>
        </div>
      ) : (
        <div>{children}</div>
      )}
      <footer className="layout-footer">
        <div className="layout-footer-container">
          <div className="layout-footer-column" style={{ paddingRight: 20, flexBasis: 350 }}>
            <LogoWhite className="logo-arinfo" />
            <div className="atlas-image-container">
              <img src={Atlas} alt="Atlas" />
            </div>
          </div>
          <div className="layout-footer-column layout-footer-column_middle">
            <h3 className="column-title">Nos marques</h3>
            <div className="link-block">
              <a className="link" href="https://arinfo.fr" target="_blank" rel="noopener noreferrer">
                Arinfo
              </a>
              <span className="description">École des métiers du numérique</span>
            </div>
            <div className="link-block">
              <a className="link" href="https://i-maginelab.com/" target="_blank" rel="noopener noreferrer">
                I-magineLab
              </a>
              <span className="description">Des ateliers créatifs pour les inventeurs de demain</span>
            </div>
            <div className="link-block">
              <a className="link" href="https://www.openspace3d.com/" target="_blank" rel="noopener noreferrer">
                OpenSpace 3D
              </a>
              <span className="description">La réalité virtuelle pour tous les esprits créatifs</span>
            </div>
          </div>
          <div className="layout-footer-column" style={{ paddingLeft: 20, flexBasis: 350 }}>
            <p className="layout-footer-column-text">Formao, une marque du groupe I-maginer</p>
            <Imaginer style={{ maxWidth: '200px' }} />
          </div>
        </div>
        <div className="layout-footer-legal-container">
          <a
            className="layout-footer-legal-link"
            target="_blank"
            href="https://arinfo.fr/mentions-legales"
            rel="noopener noreferrer"
          >
            Mentions légales
          </a>
        </div>
      </footer>
    </div>
  );
};

export default Layout;
