import { useFormContext } from 'react-hook-form';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { useUIContext } from '../context/UIContext';
import { Checkbox, Connect, Illustrated, Legend, Ordering, Organize, TrueFalse } from '../components/questions-types';
import { postQuiz } from '../functions';
import placeholder from '../assets/placeholder.png';

const instructions = {
  checkbox: 'Cocher la ou les bonnes réponses, plusieurs réponses possibles.',
  illustrated: 'Cocher la ou les bonnes réponses, plusieurs réponses possibles.',
  connect: 'Relier les éléments de gauche avec les éléments de droite. Des pièges peuvent être présents.',
  legend: 'Déplacer les éléments dans les bonnes légendes. Des pièges peuvent être présents.',
  ordering: 'Déplacer les éléments pour classer correctement la liste.',
  organize:
    'Déplacer les éléments dans les bonnes colonnes. 5 éléments maximum peuvent être placés dans chaque colonne. Des pièges peuvent être présents.',
  trueFalse: 'Sélectionner vrai ou faux.',
};

const Quiz = () => {
  const { nextQuestion, currentQuestion, quiz, nextStep, test } = useUIContext();
  const { handleSubmit } = useFormContext();

  const onSubmit = (form) => {
    postQuiz(form, test.token);
    nextStep();
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <p className="question-instructions">{instructions[currentQuestion.type]}</p>
        {currentQuestion?.image && currentQuestion.type !== 'legend' && (
          <div
            style={{
              backgroundImage: currentQuestion?.image?.secure_url
                ? `url('https://res.cloudinary.com/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/q_10,e_blur:1300/${currentQuestion.image.public_id}.${currentQuestion.image.format}')`
                : 'none',
              backgroundPosition: 'center',
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              height: currentQuestion.image.height,
              width: currentQuestion.image.width,
              maxHeight: 500,
              maxWidth: '100%',
              margin: '0 auto',
            }}
            className="question-image-container"
          >
            <img
              alt="question_image"
              className="question-image"
              src={currentQuestion.image.secure_url || placeholder}
            />
          </div>
        )}
        <p className="question">{currentQuestion?.question}</p>
        {currentQuestion.type === 'checkbox' && <Checkbox key={currentQuestion._id} />}
        {currentQuestion.type === 'connect' && <Connect key={currentQuestion._id} />}
        {currentQuestion.type === 'illustrated' && <Illustrated key={currentQuestion._id} />}
        {currentQuestion.type === 'legend' && <Legend key={currentQuestion._id} />}
        {currentQuestion.type === 'ordering' && <Ordering key={currentQuestion._id} />}
        {currentQuestion.type === 'organize' && <Organize key={currentQuestion._id} />}
        {currentQuestion.type === 'trueFalse' && <TrueFalse key={currentQuestion._id} />}
        {currentQuestion.index !== quiz.length - 1 && (
          <button type="button" className="next-step-button" onClick={nextQuestion}>
            Question suivante
          </button>
        )}
        {currentQuestion.index === quiz.length - 1 && (
          <button type="submit" className="next-step-button">
            Terminer le test
          </button>
        )}
      </form>
    </DndProvider>
  );
};

export default Quiz;
