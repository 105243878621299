import LabelsBox from './LabelsBox';
import Column from './Column';

const Organize = () => {
  return (
    <div>
      <Column />
      <LabelsBox />
    </div>
  );
};

export default Organize;
