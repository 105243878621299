import { memo, useEffect, useRef } from 'react';
import { useFormContext, Controller, useFieldArray } from 'react-hook-form';
import mediumZoom from 'medium-zoom';
import { useUIContext } from '../../context/UIContext';
import placeholder from '../../assets/placeholder.png';

const ImageComponent = memo(({ item }) => {
  const ref = useRef();

  return (
    <div
      style={{
        backgroundImage: item?.image?.secure_url
          ? `url('https://res.cloudinary.com/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/q_10,e_blur:1300/${item.image.public_id}.${item.image.format}')`
          : 'none',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        margin: '0 auto',
      }}
      className="illustrated-image-hover"
      onClick={() => ref?.current?.click()}
    >
      <img
        data-zoomable="true"
        ref={ref}
        className="illustrated-image"
        alt=""
        src={item?.image?.secure_url || placeholder}
      />
    </div>
  );
});

ImageComponent.displayName = 'ImageComponent';

const Illustrated = () => {
  const { currentQuestion } = useUIContext();
  const { control } = useFormContext();
  const { fields: illustrated } = useFieldArray({ control, name: `${currentQuestion._id}.illustrated` });

  useEffect(() => {
    mediumZoom('[data-zoomable]');
  }, []);

  return (
    <div className="illustrated-container">
      {illustrated.map((item, index) => (
        <div key={item._id} className="checkbox-illustrated-container">
          <ImageComponent item={item} />
          <label className="illustrated-checkbox-label checkbox-label-img">
            <Controller
              name={`${currentQuestion._id}.illustrated.${index}.valid`}
              key={`${currentQuestion._id}-${item.id}`}
              render={({ field }) => <input {...field} type="checkbox" className="checkbox-input" />}
            />
            <span className="checkbox-box" />
          </label>
        </div>
      ))}
    </div>
  );
};

export default Illustrated;
