import React from 'react';

const Logo = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0"
    y="0"
    enableBackground="new 0 0 350.61 144.26"
    version="1.1"
    viewBox="0 0 350.61 144.26"
    xmlSpace="preserve"
    {...props}
  >
    <path
      d="M269.29 50.3a2.67 2.67 0 00-2.67 2.67v3.17h8.93v7.19H266.61v28.79h-9.83V63.35h-6.81v-7.21h6.79V52.6c0-5.24 4.25-9.49 9.49-9.49h9.3v7.19h-6.26zM165.78 92.12V69.58c0-4.45-1.23-7.8-3.69-10.05-2.46-2.26-6.12-3.38-10.97-3.38H137c-2.83 0-5.12 2.32-5.12 5.18v2.25h15.69c3.19 0 5.4.43 6.64 1.3 1.24.87 1.86 2.37 1.86 4.5v.9H143.7c-5.08 0-8.71.89-10.89 2.67-2.19 1.78-3.28 4.71-3.28 8.8 0 3.31.95 5.87 2.85 7.67 1.9 1.8 4.62 2.7 8.16 2.7h12.07v-7.24h-8.24c-2.07 0-3.43-.25-4.1-.75-.67-.5-1.01-1.38-1.01-2.65 0-1.49.42-2.53 1.25-3.13.83-.6 2.35-.9 4.55-.9h11.07v14.68h9.65zm43.64-49.01h-4.95c-2.71 0-4.91 2.22-4.91 4.96v3.97h9.85v-8.93zm0 13.03h-9.85v35.98h9.85V56.14zm4.01 35.98h9.39V64.05h7.55c2.48 0 4.14.4 4.96 1.2.82.8 1.24 2.55 1.24 5.23v21.64h9.39v-22.8c0-4.71-1.14-8.09-3.43-10.12-2.29-2.03-6.1-3.05-11.44-3.05h-12.76c-2.71 0-4.91 2.22-4.91 4.96v31.01zm-43.64 0h9.39V63.05h4.15c1.8 0 3.27 1.48 3.27 3.31v4.86h8.97v-6c0-3.36-.74-5.71-2.22-7.05-1.48-1.34-4.07-2.02-7.76-2.02h-12.52c-1.81 0-3.27 1.48-3.27 3.31v32.66zm143.23-17.65c0-5.56-1.69-10.01-5.06-13.34-3.4-3.33-7.94-4.99-13.62-4.99-5.71 0-10.27 1.66-13.69 4.99-3.4 3.33-5.1 7.77-5.1 13.34 0 5.51 1.7 9.93 5.1 13.23 3.4 3.33 7.96 4.99 13.69 4.99 5.73 0 10.28-1.65 13.66-4.96 3.35-3.29 5.02-7.72 5.02-13.26zm-27.67 0c0-3.35.78-5.97 2.35-7.85 1.59-1.88 3.78-2.82 6.56-2.82 2.83 0 5.03.94 6.6 2.82 1.59 1.85 2.39 4.47 2.39 7.85 0 3.35-.8 5.97-2.39 7.84-1.59 1.88-3.79 2.82-6.6 2.82s-4.99-.94-6.56-2.82c-1.57-1.87-2.35-4.48-2.35-7.84z"
      fill="#2E5597"
    ></path>
    <path d="M119.75 72.13L97.77 72.13 58.13 36.55 99.21 36.55z" fill="#9BA1CB"></path>
    <path d="M58.13 36.55L69.12 55.58 58.13 107.71 37.59 72.13z" fill="#6777B0"></path>
    <path d="M58.13 107.71L69.12 88.68 119.75 72.13 99.21 107.71z" fill="#2E5597"></path>
    <path d="M58.13 107.71L69.12 55.58 78.67 72.13z" fill="#d7d9eb"></path>
    <path d="M58.13 36.55L97.77 72.13 78.67 72.13z" fill="#f5f5fb"></path>
    <path d="M119.75 72.13L69.12 88.68 78.67 72.13z" fill="#FFFFFF"></path>
  </svg>
);

export default Logo;
