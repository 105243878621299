import { memo, useState } from 'react';
import { useForm, FormProvider, useFormContext, Controller, useFormState } from 'react-hook-form';
import cn from 'classnames';
import { useUIContext } from '../context/UIContext';
import { postEvaluation } from '../functions';
import Loading from '../components/Loading';

const CheckboxField = memo(({ fields, name, disabled }) => {
  const { control, getValues } = useFormContext();
  const { errors } = useFormState({ control });

  const handleChange = (item, callback) => {
    const { text, custom } = item;
    const value = custom ? 'CUSTOM' : text;
    const newValue = getValues(name) || [];

    if (newValue.includes(value)) {
      const index = newValue.indexOf(value);
      newValue.splice(index, 1);
    } else {
      newValue.push(value);
    }

    callback(newValue);
  };

  return (
    <div className="evaluation-checkbox">
      <Controller
        name={name}
        control={control}
        render={({ field }) =>
          fields.map((item, index) => (
            <div key={index} className="checkbox-container">
              <label className="checkbox-label">
                <input
                  checked={field.value?.includes(item.custom ? 'CUSTOM' : item.text) || false}
                  onChange={() => handleChange(item, field.onChange)}
                  type="checkbox"
                  className="checkbox-input"
                  disabled={disabled}
                />
                <div
                  className={cn('checkbox-box', {
                    'checkbox-box-error': errors?.[name],
                    'checkbox-box-disabled': disabled,
                  })}
                />
                <p className={cn('checkbox-text', { 'checkbox-text-nowrap': item.custom })}>{item.text}</p>
                {item.custom && (
                  <Controller
                    name={`${name}_custom`}
                    control={control}
                    render={({ field: nestedField }) => (
                      <input
                        value={nestedField.value || ''}
                        onChange={(event) => {
                          nestedField.onChange(event);

                          if (!field.value?.includes('CUSTOM')) {
                            handleChange(item, field.onChange);
                          }
                        }}
                        className={cn('form-input evaluation-custom-input', {
                          'form-input-error': errors?.[`${name}_custom`],
                        })}
                        type="text"
                        disabled={disabled}
                      />
                    )}
                  />
                )}
              </label>
            </div>
          ))
        }
      />
    </div>
  );
});

const LevelField = memo(({ name, levels, labels, disabled }) => {
  const { control } = useFormContext();
  const { errors } = useFormState({ control });
  const fields = Array.from(Array(levels).keys())
  
  return (
    <div className="evaluation-level">
      {labels?.from}
      <Controller
        name={name}
        control={control}
        render={({ field }) =>
          fields.map((item, index) => (
            <div key={index} className="radio-container">
              <label className="radio-label">
                <p className="radio-text">{item}</p>
                <input
                  name={field.name}
                  checked={field.value === item}
                  onChange={() => field.onChange(item)}
                  type="radio"
                  className="radio-input"
                  disabled={disabled}
                />
                <div
                  className={cn('radio-box', {
                    'radio-box-error': errors?.[name],
                    'radio-box-disabled': disabled,
                  })}
                />
              </label>
            </div>
          ))
        }
      />
      {labels?.to}
    </div>
  );
});

const RadioField = memo(({ name, fields, disabled }) => {
  const { control } = useFormContext();
  const { errors } = useFormState({ control });

  const handleChange = (item, callback) => {
    const { text, custom } = item;
    const value = custom ? 'CUSTOM' : text;

    callback(value);
  };

  return (
    <div className="evaluation-radio">
      <Controller
        name={name}
        control={control}
        render={({ field }) =>
          fields.map((item, index) => (
            <div key={index} className="radio-container">
              <label key={index} className="radio-label">
                <input
                  name={field.name}
                  checked={item.custom ? field.value === 'CUSTOM' : field.value === item.text}
                  onChange={() => handleChange(item, field.onChange)}
                  type="radio"
                  className="radio-input"
                  disabled={disabled}
                />
                <div
                  className={cn('radio-box', {
                    'radio-box-error': errors?.[name],
                    'radio-box-disabled': disabled,
                  })}
                />
                <p className={cn('radio-text', { 'radio-text-nowrap': item.custom })}>{item.text}</p>
                {item.custom && (
                  <Controller
                    name={`${name}_custom`}
                    control={control}
                    render={({ field: nestedField }) => (
                      <input
                        name={nestedField.name}
                        value={nestedField.value || ''}
                        onChange={(event) => {
                          nestedField.onChange(event);

                          if (field.value !== 'CUSTOM') {
                            handleChange(item, field.onChange);
                          }
                        }}
                        className={cn('form-input evaluation-custom-input', {
                          'form-input-error': errors?.[`${name}_custom`],
                        })}
                        type="text"
                        disabled={disabled}
                      />
                    )}
                  />
                )}
              </label>
            </div>
          ))
        }
      />
    </div>
  );
});

CheckboxField.displayName = 'CheckboxField';
LevelField.displayName = 'LevelField';
RadioField.displayName = 'RadioField';

const Evaluation = () => {
  const [isProcessing, setIsProcessing] = useState(false);
  const { nextStep, evaluation, test } = useUIContext();
  const methods = useForm();
  const { errors } = useFormState({ control: methods.control });

  const onSubmit = (form) => {
    setIsProcessing(true);

    postEvaluation(form, test.token)
      .then(() => {
        nextStep();
      })
      .catch((err) =>
        Object.entries(err).forEach(([key, value]) => {
          methods.setError(key, { type: 'manual', message: value });
        }),
      )
      .finally(() => {
        setIsProcessing(false);
      });
  };

  return (
    <FormProvider {...methods}>
      <p className="question-instructions">
        {evaluation.form.length} question{evaluation.form.length > 1 ? 's' : ''} pour nous permettre de connaître votre
        état actuel de connaissance sur l'outil ou le domaine.
      </p>
      <form noValidate onSubmit={methods.handleSubmit(onSubmit)}>
        {evaluation.form.map((item, index) => (
          <div key={item._id} className="evaluation-item">
            <h3 className="evaluation-title">{item.title}</h3>
            {item?.description && <p className="evaluation-description">{item.description}</p>}
            {item.format === 'CHECKBOX' && (
              <CheckboxField name={item._id} fields={item.fields} disabled={isProcessing} />
            )}
            {item.format === 'LEVEL' && (
              <LevelField name={item._id} levels={item.levels} labels={item?.labels} disabled={isProcessing} />
            )}
            {item.format === 'RADIO' && <RadioField name={item._id} fields={item.fields} disabled={isProcessing} />}
          </div>
        ))}
        {Object.keys(errors).length !== 0 && <div className="evaluation-alert">Veuillez compléter le formulaire</div>}
        <button disabled={isProcessing} type="submit" className="next-step-button">
          {isProcessing ? <Loading /> : 'Valider'}
        </button>
      </form>
    </FormProvider>
  );
};

export default Evaluation;
