import LineTo from 'react-lineto';
import { useState, useEffect, useCallback, useMemo } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useUIContext } from '../../../context/UIContext';
import LegendBoxLabel from './LegendBoxLabel';
import placeholder from '../../../assets/placeholder.png';

const getSides = (items) => {
  items.forEach((item, index) => {
    items[index] = { ...item, index };
  });

  let leftSide = items.filter((item) => item.coords[0] <= 225) || [];
  let rightSide = items.filter((item) => item.coords[0] > 225) || [];

  if (leftSide.length > 5) {
    const toRemove = leftSide.length - 5;
    const cleanSide = [...leftSide].sort((a, b) => a.coords[0] - b.coords[0]);
    const rest = cleanSide.splice(5, toRemove);

    leftSide = cleanSide;
    rightSide = rightSide.concat(rest);
  }

  if (rightSide.length > 5) {
    const toRemove = rightSide.length - 5;
    const cleanSide = [...rightSide].sort((a, b) => a.coords[0] - b.coords[0]).reverse();
    const rest = cleanSide.splice(5, toRemove);

    rightSide = cleanSide;
    leftSide = leftSide.concat(rest);
  }

  return {
    leftSide: leftSide.sort((a, b) => a.coords[1] - b.coords[1]),
    rightSide: rightSide.sort((a, b) => a.coords[1] - b.coords[1]),
  };
};

const getLinesColor = (currentQuestion) => {
  const linesColor = currentQuestion.legend.linesColor;

  if (linesColor) {
    if (linesColor === 'dark') {
      return '#315397';
    }

    if (linesColor === 'light') {
      return '#91CAFF';
    }

    if (linesColor === 'default') {
      return '#315397';
    }
  } else {
    return '#315397';
  }
};

const LegendBox = () => {
  const { currentQuestion } = useUIContext();
  const { control } = useFormContext();
  //prettier-ignore
  const { fields: legends, update} = useFieldArray({ name: `${currentQuestion._id}.legend.lists`, control });
  const { leftSide, rightSide } = getSides(legends);
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  const linesColor = useMemo(() => getLinesColor(currentQuestion), [currentQuestion]);

  useEffect(() => {
    const Listener = () => {
      forceUpdate();
    };
    window.addEventListener('scroll', Listener);
    window.addEventListener('resize', Listener);

    return () => {
      window.removeEventListener('resize', Listener);
      window.removeEventListener('scroll', Listener);
    };
  }, [forceUpdate]);

  return (
    <div className="legend-container">
      <div style={{ flex: 1, marginRight: 24 }}>
        {leftSide.map((legend) => (
          <div key={legend._id} className={`${legend._id} legend-element`}>
            <LegendBoxLabel data={{ ...legend }} update={update} />
            <LineTo
              from={`${legend._id}`}
              to={`point-${legend._id}`}
              fromAnchor="right"
              toAnchor="center"
              borderWidth={1}
              borderColor={linesColor}
              delay={true}
              className="lines"
            />
          </div>
        ))}
      </div>
      <div
        style={{
          backgroundImage: currentQuestion?.image?.secure_url
            ? `url('https://res.cloudinary.com/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/q_10,e_blur:1300/${currentQuestion.image.public_id}.${currentQuestion.image.format}')`
            : 'none',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          margin: '0 auto',
        }}
        className="legend-img-container"
      >
        <img src={currentQuestion?.image?.secure_url || placeholder} className="legend-img " alt="" id="legend__img" />
        {currentQuestion.legend.lists.map((legend) => (
          <span
            key={legend._id}
            className={`point-${legend._id}`}
            style={{
              position: 'absolute',
              top: legend.coords[1],
              left: legend.coords[0],
              border: `5px solid ${linesColor}`,
              borderRadius: '5px',
            }}
          />
        ))}
      </div>
      <div style={{ flex: 1, marginLeft: 24 }}>
        {rightSide.map((legend) => (
          <div key={legend._id} className={`${legend._id} legend-element`}>
            <LegendBoxLabel data={{ ...legend }} update={update} />
            <LineTo
              from={`${legend._id}`}
              to={`point-${legend._id}`}
              fromAnchor="left"
              toAnchor="center"
              borderWidth={1}
              borderColor={linesColor}
              delay={true}
              className="lines"
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default LegendBox;
