const End = () => (
  <div className="end-step">
    <p className="end-paragraph">Vos résultats ont été transmis au référent du centre ARINFO que vous avez contacté.</p>
    <p className="end-paragraph">
      Un récapitulatif du test vous a été envoyé par mail. Nous vous recontacterons sous peu.
    </p>
  </div>
);

export default End;
