import { useEffect } from 'react';
import { isDesktop } from 'react-device-detect';
import { useUIContext } from '../context/UIContext';
import { getTest } from '../functions';

// ? addEventListener window resize ?
const Loading = () => {
  const { nextStep, setError, setData } = useUIContext();

  useEffect(() => {
    getTest()
      .then((data) => {
        const windowWidth = window.innerWidth;

        if (!isDesktop) {
          return setError('DEVICE_ERROR');
        }

        if (windowWidth < 1050) {
          return setError('WIDTH_ERROR');
        }

        setData(data);
        nextStep();
      })
      .catch((err) => setError(err));
  }, []);

  return null;
};

export default Loading;
