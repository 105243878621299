const Fallback = ({ error }) => {
  return (
    <div role="alert">
      <p>Une erreur est survenue</p>
      <pre style={{ color: 'red' }}>{error?.message}</pre>
    </div>
  );
};

export default Fallback;
