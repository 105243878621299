import React from 'react';

const Loading = () => (
  <div className={`loading-container`}>
    <svg className="spinner" viewBox="0 0 50 50">
      <circle className="path" cx="25" cy="25" r="20" fill="none" stroke="#324650" strokeWidth="5"></circle>
    </svg>
  </div>
);

export default Loading;
