import { useUIContext } from '../context/UIContext';

const Instructions = () => {
  const { nextStep, evaluation } = useUIContext();

  return (
    <div>
      <div style={{ display: 'flex' }}>
        <div style={{ flex: 1, marginRight: 24 }}>
          <p className="instructions-paragraph" style={{ marginTop: 0 }}>
            Vous allez maintenant passer un test de positionnement. Voici les modalités.
          </p>
          <p className="instructions-paragraph" style={{ marginTop: 0 }}>
            Petit conseil : prenez le temps de bien lire les consignes et si vous ne connaissez pas la réponse, laissez
            vide.
          </p>
          {'name' in evaluation && (
            <div>
              <h3 className="instructions-title">Auto-évaluation :</h3>
              <ul className="instructions-list">
                <li className="instructions-text">
                  {evaluation.form.length} question{evaluation.form.length > 1 ? 's' : ''} pour nous permettre de
                  connaître votre état actuel de connaissance sur l'outil ou le domaine.
                </li>
              </ul>
            </div>
          )}
          <h3 className="instructions-title">30 questions :</h3>
          <ul className="instructions-list">
            <li className="instructions-text">
              10 questions relatives à l'informatique (composants PC, périphériques, raccourcis clavier, etc)
            </li>
            <li className="instructions-text">20 questions en lien avec le domaine de formation visé</li>
          </ul>
          <h3 className="instructions-title">Temps limité :</h3>
          <p className="instructions-text">
            Vous disposez de 30 à 90 secondes pour répondre à chaque question. Une fois le temps écoulé, le test passe
            automatiquement à la question suivante.
          </p>
        </div>
        <div style={{ flex: 1 }}>
          <h3 className="instructions-title" style={{ marginTop: 0 }}>
            Différents types de questions :
          </h3>
          <ul className="instructions-list">
            <li className="instructions-text">Cases à cocher</li>
            <p className="instructions-types-description">
              Une question accompagnée ou non par une image avec plusieurs choix de réponses. Il est possible de cliquer
              sur les images afin de les agrandir. Plusieurs réponses possibles.
            </p>
            <li className="instructions-text">Éléments à trier</li>
            <p className="instructions-types-description">
              Un certain nombre d'éléments à placer dans les bonnes colonnes. 5 éléments maximum peuvent être placés
              dans chaque colonne. Des pièges peuvent être présents.
            </p>
            <li className="instructions-text">Image légendée</li>
            <p className="instructions-types-description">
              Une image possédant des légendes à compléter avec les différents éléments. Des pièges peuvent être
              présents.
            </p>
            <li className="instructions-text">Liste à ordonner</li>
            <p className="instructions-types-description">Une liste contenant des élément à classer.</p>
            <li className="instructions-text">Points à relier</li>
            <p className="instructions-types-description">
              Deux listes contenant différents éléments à relier entre eux. Des pièges peuvent être présents.
            </p>
            <li className="instructions-text">Vrai ou faux</li>
            <p className="instructions-types-description">Une affirmation, vraie ou fausse ?</p>
          </ul>
          <p>À la fin du test vous recevrez un récapitulatif par mail.</p>
        </div>
      </div>
      <p className="instructions-title" style={{ textAlign: 'center' }}>
        Bonne chance !
      </p>
      <button type="submit" className="next-step-button" onClick={nextStep}>
        Commencer le test
      </button>
    </div>
  );
};

export default Instructions;
