import { useState, useEffect, useCallback } from 'react';
import LineTo from 'react-lineto';
import cn from 'classnames';
import { useFormContext, useFieldArray } from 'react-hook-form';
import { useUIContext } from '../../context/UIContext';

const Connect = () => {
  const { currentQuestion } = useUIContext();
  const { control } = useFormContext();
  const { fields: lines, append, replace } = useFieldArray({ control, name: `${currentQuestion._id}.connect.lines` });
  const [selection, setSelection] = useState({ from: null, to: null });
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);

  useEffect(() => {
    if (selection.from !== null && selection.to !== null) {
      let fromIndex = lines.findIndex(({ from }) => from === selection.from);
      let toIndex = lines.findIndex(({ to }) => to === selection.to);

      if (fromIndex === -1 && toIndex === -1) {
        append({ from: selection.from, to: selection.to });
      } else {
        if (fromIndex !== toIndex) {
          const newLines = [...lines];

          if (fromIndex !== -1) {
            fromIndex = newLines.findIndex(({ from }) => from === selection.from);
            newLines.splice(fromIndex, 1);
          }

          if (toIndex !== -1) {
            toIndex = newLines.findIndex(({ to }) => to === selection.to);
            newLines.splice(toIndex, 1);
          }

          newLines.push({ from: selection.from, to: selection.to });
          replace(newLines);
        }
      }
      setSelection({ from: null, to: null });
    }
  }, [lines, selection.from, selection.to]);

  useEffect(() => {
    const Listener = () => {
      forceUpdate();
    };
    window.addEventListener('scroll', Listener);
    window.addEventListener('resize', Listener);

    return () => {
      window.removeEventListener('resize', Listener);
      window.removeEventListener('scroll', Listener);
    };
  }, [forceUpdate]);

  return (
    <div>
      <div className="connect-container">
        <div className="connect-fromlist-container">
          {currentQuestion.connect.fromList.map((list) => (
            <div key={list._id} className="connect-fromlist-element">
              {list.content}
              <div
                onClick={() => setSelection((rest) => ({ ...rest, from: list._id }))}
                className={cn(`square square-${list._id} square-from`, {
                  selected: selection.from === list._id && lines.findIndex(({ from }) => from === list._id) === -1,
                  linked: lines.findIndex(({ from }) => from === list._id) !== -1,
                  linked_selected:
                    selection.from === list._id && lines.findIndex(({ from }) => from === list._id) !== -1,
                })}
              />
            </div>
          ))}
        </div>
        <div className="connect-tolist-container">
          {currentQuestion.connect.toList.map((list) => (
            <div key={list._id} className="connect-tolist-element">
              <div
                onClick={() => setSelection((rest) => ({ ...rest, to: list._id }))}
                className={cn(`square square-${list._id} square-to`, {
                  selected: selection.to === list._id && lines.findIndex(({ to }) => to === list._id) === -1,
                  linked: lines.findIndex(({ to }) => to === list._id) !== -1,
                  linked_selected: selection.to === list._id && lines.findIndex(({ to }) => to === list._id) !== -1,
                })}
              />
              {list.content}
            </div>
          ))}
        </div>
      </div>
      {lines.map(({ from, to }, index) => (
        <LineTo
          className="lines"
          key={index}
          from={`square-${from}`}
          fromAnchor="right"
          toAnchor="left"
          to={`square-${to}`}
          borderWidth={1}
          borderColor="#315397"
        />
      ))}
    </div>
  );
};

export default Connect;
