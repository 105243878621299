'use client';

import React from 'react';
import ReactDOM from 'react-dom/client';
import { ErrorBoundary } from 'react-error-boundary';
import { UIProvider } from './context/UIContext';
import App from './App';
import 'react-datepicker/dist/react-datepicker.css';
import { Fallback } from './views';

const root = ReactDOM.createRoot(document.getElementById('root'));

const logError = async (error, info) => {
  await fetch(`${process.env.REACT_APP_ERROR_ENDPOINT}/posiao`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ error, info }),
  });
};

root.render(
  <ErrorBoundary FallbackComponent={Fallback} onError={logError}>
    <UIProvider>
      <App />
    </UIProvider>
  </ErrorBoundary>,
);
