import { useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { useForm, Controller } from 'react-hook-form';
import ReactSelect from 'react-select';
import fr from 'date-fns/locale/fr';
import cn from 'classnames';
import Swal from 'sweetalert2';
import { useUIContext } from '../context/UIContext';
import regions from '../dataset/regions.json';
import { postForm } from '../functions';
import Loading from '../components/Loading';

registerLocale('fr', fr);

const Form = () => {
  const [isProcessing, setIsProcessing] = useState(false);
  const { test, nextStep } = useUIContext();
  //prettier-ignore
  const { control, handleSubmit, setError, formState: { errors } } = useForm({
    defaultValues: {
      phone: test?.contact?.phone ?? '',
      birth_date: test?.identity?.birth_date ? new Date(test?.identity?.birth_date) : '',
      state: test?.address?.state ?? '',
      postal_code: test?.address?.postal_code ?? '',
      city: test?.address?.city ?? '',
      street: test?.address?.street ?? '',
      professional_background: test?.identity?.professional_background ?? '',
      project_description: test?.identity.project_description ?? '',
    },
  });
  const lastName = test?.identity?.last_name?.charAt(0).toUpperCase() + test?.identity?.last_name?.slice(1);
  const firstName = test?.identity?.first_name?.charAt(0).toUpperCase() + test?.identity?.first_name?.slice(1);

  const onSubmit = (form) => {
    setIsProcessing(true);

    postForm(form, test.token)
      .then(() => nextStep())
      .catch((err) => {
        if (typeof err === 'object' && Object.keys(err).length !== 0) {
          Object.entries(err).forEach(([key, value]) => {
            setError(key, { type: 'manual', message: value });
          });
        } else {
          Swal.fire({
            width: 600,
            title: 'Une erreur est survenue',
            html:
              '<div style="font-size: 16px;">' +
              `<p style="text-align:left; color: #f27474">${err}</p>` +
              '<p style="text-align:left; margin: 0;">Si ce problème persiste veuillez contacter notre support technique via l\'email <a href="mailto:jbrasier@arinfo.fr?subject=Problème de fonctionnement du test de positionnement">jbrasier@arinfo.fr</a>.</p>' +
              '<p style="text-align:left;">En décrivant votre problème avec les données suivantes :</p>' +
              '<ul>' +
              "<li style='text-align:left;'>La description de l'erreur si présente (Le texte en rouge)</li>" +
              "<li style='text-align:left;'>Une capture d'écran du problème</li>" +
              '<li style="text-align:left;">Votre navigateur et si possible sa version</li>' +
              '<li style="text-align:left;">Votre OS (Windows 10/11..., MacOs Ventura/Monterey..., Ubuntu...)</li>' +
              '<li style="text-align:left;">Votre appareil et sa marque (Ordinateur ASUS, Téléphone Samsung...)</li>' +
              '</ul>' +
              '<p style="text-align:left;">Solutions possibles :</p>' +
              '<ul>' +
              "<li style='text-align:left;'>Rafraîchir la page et réessayer</li>" +
              "<li style='text-align:left;'>Changer de navigateur</li>" +
              '</ul>' +
              '</div>',
            icon: 'error',
            confirmButtonText: 'Rafraîchir la page',
            confirmButtonColor: '#315397',
          }).then((result) => {
            window.location.reload();
          });
        }
      })
      .finally(() => {
        setIsProcessing(false);
      });
  };

  return (
    <div className="form-container">
      <p>
        Bonjour {firstName} {lastName},<br />
        Dans le cadre d'un projet de formation, vous vous apprêtez à passer un test de positionnement. Avant cela, merci
        de compléter quelques informations.
      </p>
      <p className="form-help">Les champs marqués d'une astérisque (*) sont obligatoires</p>
      <form className="form" onSubmit={handleSubmit(onSubmit)}>
        <div className="form-row">
          <label className="form-col">
            <Controller
              name="phone"
              control={control}
              render={({ field }) => (
                <input
                  {...field}
                  onChange={(event) => field.onChange(event.target.value.replace(/[^0-9]/g, ''))}
                  className={cn('form-input', { 'form-input-error': errors?.phone })}
                  type="text"
                  placeholder="Numéro de téléphone *"
                  disabled={isProcessing}
                  maxLength="10"
                />
              )}
            />
            {errors?.phone && <small className="error-message">{errors?.phone?.message}</small>}
          </label>
          <label className="form-col">
            <Controller
              name="birth_date"
              control={control}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  selected={field.value}
                  placeholderText="Date de naissance *"
                  locale="fr"
                  peekNextMonth
                  dateFormat="dd/MM/yyyy"
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  wrapperClassName="form-datepicker"
                  className={cn('form-input', { 'form-input-error': errors?.birth_date })}
                  readOnly={isProcessing}
                />
              )}
            />
            {errors?.birth_date && <small className="error-message">{errors?.birth_date?.message}</small>}
          </label>
        </div>
        <div className="form-row">
          <label className="form-col">
            <Controller
              name="state"
              control={control}
              render={({ field }) => (
                <ReactSelect
                  {...field}
                  value={regions.filter((item) => item.name === field.value)}
                  onChange={(opt) => field.onChange(opt.name)}
                  options={regions}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.name}
                  isClearable
                  placeholder="Région *"
                  className={cn('form-select', { 'form-select-error': errors?.state })}
                  isDisabled={isProcessing}
                  styles={{
                    control: (s, { isDisabled }) => ({
                      ...s,
                      opacity: isDisabled ? 0.7 : 1,
                      boxShadow: errors?.state ? '0 0 0 1px #E73333' : 'none',
                      border: errors?.state ? '1px solid #E73333' : '1px solid #cccccc',
                      '&:hover': {
                        border: errors?.state ? '1px solid #E73333' : '1px solid #2684ff',
                      },
                    }),
                    singleValue: (s) => ({
                      ...s,
                      color: '#324650',
                    }),
                  }}
                />
              )}
            />
            {errors?.state && <small className="error-message">{errors?.state?.message}</small>}
          </label>
          <label className="form-col">
            <Controller
              name="postal_code"
              control={control}
              render={({ field }) => (
                <input
                  {...field}
                  className={cn('form-input', { 'form-input-error': errors?.postal_code })}
                  type="text"
                  placeholder="Code postal *"
                  disabled={isProcessing}
                  maxLength="5"
                />
              )}
            />
            {errors?.postal_code && <small className="error-message">{errors?.postal_code?.message}</small>}
          </label>
        </div>
        <div className="form-row">
          <label className="form-col">
            <Controller
              name="city"
              control={control}
              render={({ field }) => (
                <input
                  {...field}
                  className={cn('form-input', { 'form-input-error': errors?.city })}
                  type="text"
                  placeholder="Commune *"
                  disabled={isProcessing}
                />
              )}
            />
            {errors?.city && <small className="error-message">{errors?.city?.message}</small>}
          </label>
          <label className="form-col">
            <Controller
              name="street"
              control={control}
              render={({ field }) => (
                <input
                  {...field}
                  className={cn('form-input', { 'form-input-error': errors?.street })}
                  type="text"
                  placeholder="Adresse *"
                  disabled={isProcessing}
                />
              )}
            />
            {errors?.street && <small className="error-message">{errors?.street?.message}</small>}
          </label>
        </div>
        <div className="form-row">
          <label className="form-label">
            <Controller
              name="professional_background"
              control={control}
              render={({ field }) => (
                <textarea
                  {...field}
                  maxLength="500"
                  className={cn('form-textarea', { 'form-textarea-error': errors?.professional_background })}
                  placeholder="Décrivez en quelques lignes votre parcours professionnel et/ou vos attentes de la formation *"
                  disabled={isProcessing}
                />
              )}
            />
            {errors?.professional_background && (
              <small className="error-message">{errors?.professional_background?.message}</small>
            )}
          </label>
        </div>
        <div className="form-row">
          <label className="form-label">
            <Controller
              name="project_description"
              control={control}
              render={({ field }) => (
                <textarea
                  {...field}
                  maxLength="500"
                  className={cn('form-textarea', { 'form-textarea-error': errors?.project_description })}
                  placeholder="Décrivez en quelques lignes votre projet professionnel et votre situation actuelle *"
                  disabled={isProcessing}
                />
              )}
            />
            {errors?.project_description && (
              <small className="error-message">{errors?.project_description?.message}</small>
            )}
          </label>
        </div>
        <i style={{ color: '#9BA1CB' }}>
          (Ces informations sont collectées pour la création de votre dossier et sont à usage exclusif d'Arinfo. Pour
          plus de précision, n'hésitez pas à consulter les mentions légales ci-dessous.)
        </i>
        <button disabled={isProcessing} type="submit" className="form-submit">
          {isProcessing ? <Loading /> : 'Valider'}
        </button>
      </form>
    </div>
  );
};

export default Form;
