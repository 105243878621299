import { useFormContext, useFieldArray } from 'react-hook-form';
import { useDrop } from 'react-dnd';
import { useRef } from 'react';
import { useUIContext } from '../../../context/UIContext';
import Label from './Label';

const ColumnLabels = ({ listIndex }) => {
  const ref = useRef(null);
  const { currentQuestion } = useUIContext();
  const { control } = useFormContext();
  //prettier-ignore
  const {fields: labels, remove, append} = useFieldArray({ name: `${currentQuestion._id}.organize.lists.${listIndex}.labels`, control })
  const [{ canDrop, isOver }, drop] = useDrop({
    accept: 'LABEL',
    collect: (monitor) => ({
      canDrop: monitor.canDrop(),
      isOver: monitor.isOver(),
    }),
    drop: (item) => {
      if (item.listIndex === listIndex || labels?.length === 5) return;

      const itemCopy = { ...item };
      delete itemCopy.id;
      delete itemCopy.index;
      delete itemCopy.listIndex;
      delete itemCopy.remove;

      item.remove();
      append(itemCopy);
    },
  });
  drop(ref);

  return (
    <div
      className="organize-column-labels"
      ref={ref}
      style={{
        backgroundColor: isOver ? '#9BA1CB' : canDrop ? '#F5F5FB' : 'white',
      }}
    >
      {labels.map((label, index) => (
        <Label key={label.id} data={{ ...label, index, listIndex, remove: () => remove(index) }} />
      ))}
    </div>
  );
};

export default ColumnLabels;
