import { useEffect, useMemo } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useUIContext } from './context/UIContext';
import { Loading, Form, Instructions, End, Quiz, Evaluation } from './views';
import Layout from './components/Layout';
import { postQuiz } from './functions';
import './App.css';

const getDefault = (quiz) => {
  const obj = {};

  for (let i = 0; i < quiz.length; i++) {
    obj[quiz[i]._id] = quiz[i];
  }

  return obj;
};

const App = () => {
  const { currentStep, quiz, test, nextStep } = useUIContext();
  const methods = useForm({ defaultValues: useMemo(() => getDefault(quiz), [quiz]) });

  useEffect(() => methods.reset(getDefault(quiz)), [quiz]);

  const onSubmit = (form) => {
    postQuiz(form, test.token);
    nextStep();
  };

  return (
    <FormProvider {...methods}>
      <Layout onTimeOut={methods.handleSubmit(onSubmit)}>
        {currentStep === 'loading' && <Loading />}
        {currentStep === 'form' && <Form />}
        {currentStep === 'instructions' && <Instructions />}
        {currentStep === 'evaluation' && <Evaluation />}
        {currentStep === 'quiz' && <Quiz />}
        {currentStep === 'end' && <End />}
        {currentStep === 'error' && null}
      </Layout>
    </FormProvider>
  );
};

export default App;
